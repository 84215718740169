<template>
  <el-container>
    <el-header height="80px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>礼物提货系统</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-tabs
            v-loading.fullscreen.lock="loading"
            v-model="activeName"
            type="border-card"
            @tab-click="handleClick"
          >
            <el-tab-pane label="国区礼物提货" name="first">
              <el-form ref="form" :model="form" :rules="dataRule">
                <span>CDK</span>
                <el-form-item prop="cdk">
                  <el-input
                    v-model="form.cdk"
                    placeholder="请输入CDK"
                  ></el-input>
                </el-form-item>
                <span>Steam好友链接</span>
                <el-form-item prop="steamHomeUrl">
                  <el-input
                    v-model="form.steamHomeUrl"
                    placeholder="请输入好友链接"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="extractGift()"
                    >提货</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="提货进度查询" name="second">
              <el-form ref="queryForm" :model="queryForm" :rules="queryRule">
                <span>CDK</span>
                <el-form-item prop="queryCdk">
                  <el-input
                    v-model="queryForm.queryCdk"
                    placeholder="请输入CDK"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="queryProgress()"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
              <el-table
                :data="tableData"
                style="width: 100%"
                v-show="showTable"
                :fit="false"
                height="250"
              >
                <el-table-column prop="cdk" label="兑换码" width="180">
                </el-table-column>
                <el-table-column prop="title" label="游戏名" width="180">
                </el-table-column>
                <el-table-column prop="steamId" label="steamId" width="180">
                </el-table-column>
                <el-table-column prop="account" label="昵称"> </el-table-column>
                <el-table-column
                  prop="botSteamId"
                  label="机器人steamId"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="botSteamAccount" label="机器人昵称">
                </el-table-column>
                <el-table-column prop="status" label="状态" width="180">
                  <template slot-scope="scope">
                    <p v-if="tableData[scope.$index].status == 2">
                      等待通过好友
                    </p>
                    <p v-if="tableData[scope.$index].status == 3">
                      等待发送礼物
                    </p>
                    <p v-if="tableData[scope.$index].status == 4">
                      礼物已发送
                    </p>
                    <p v-if="tableData[scope.$index].status == 5">赠送失败</p>
                    <p v-if="tableData[scope.$index].status == 6">已领取礼物</p>
                    <p v-if="tableData[scope.$index].status == 7">礼物已退款</p>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
    <el-footer height="60px">
      <h2>常见问题</h2>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-collapse>
            <el-collapse-item
              title="登录不上Steam或者打不开添加好友页面"
              name="1"
            >
              <div>可以下载UU加速器加速Steam商店，是免费的。</div>
              <div>下载地址：https://uu.163.com</div>
            </el-collapse-item>
            <el-collapse-item title="如何查看Steam好友链接" name="2">
              <br />
              <el-image
                style="width: 100px; height: 100px"
                :src="require('@/assets/view-steam-url.png')"
                :preview-src-list="[require('@/assets/view-steam-url.png')]"
              >
              </el-image>
            </el-collapse-item>
            <el-collapse-item title="查询进度显示赠送礼物失败" name="4">
              <div>请联系客服处理</div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      spanValue: 12,
      offsetValue: 6,
      activeName: "first",
      loading: false,
      showTable: false,
      form: {
        cdk: "",
        steamHomeUrl: "",
      },
      queryForm: {
        queryCdk: "",
      },
      dataRule: {
        cdk: [
          { required: true, message: "CDK不能为空", trigger: "blur" },
        ],
        steamHomeUrl: [
          { required: true, message: "steam个人链接不能为空", trigger: "blur" },
        ],
      },
      queryRule: {
        queryCdk: [
          { required: true, message: "CDK不能为空", trigger: "blur" },
        ],
      },
      tableData: [],
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 12;
      this.offsetValue = 6;
    }
  },
  created(){
    if(this.$route.query.cdk){
      this.form.cdk = this.$route.query.cdk;
      this.queryForm.queryCdk = this.$route.query.cdk;
    }
  },
  methods: {
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    extractGift() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("guest/gift/exchange", this.form)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.loading = false;
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                });
              } else {
                this.loading = false;
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
    queryProgress() {
      this.tableData = [];
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          this.$http
            .get("guest/gift/queryProgress/" + this.queryForm.queryCdk)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.showTable = true;
                this.tableData.push(data.progress);
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style>
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}

.el-main span {
  text-align: left;
}

/* body > .el-container {
    margin-bottom: 40px;
  } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
</style>
